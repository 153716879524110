<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitLogin)">
      <div
        class="card card-login"
        style="
          opacity: 1;
          transform: translate3d(0px, 0px, 0px);
          transition: all 0.3s ease-in 0s;
        "
      >
        <div class="card-header">
          <div>
            <h3 class="card-title text-center">Iniciar Sesión</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Correo"
              rules="required|email|max:255"
            >
              <base-input
                v-model="email"
                type="email"
                label="Correo"
                placeholder="correo"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Contraseña"
              rules="required|min:6"
            >
              <base-input
                v-model="password"
                type="password"
                label="Contraseña"
                placeholder="contraseña"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-fill btn-info btn-round btn-wd"
            :disabled="isLoading"
          >
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="isLoading">Cargando...</span>
            <span v-else>Enviar</span>
          </button>
        </div>
      </div>
      <div v-if="alertMessage" :class="`alert ${alertType}`" role="alert">
        {{ alertMessage }}
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from "axios";
import { useAuthUserStore } from "../stores/auth.store";
import { mapActions } from "pinia";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

export default {
  name: "Login",
  components: { ValidationProvider, ValidationObserver },
  data: () => {
    return {
      email: "",
      password: "",
      alertMessage: "",
      alertType: "",
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(useAuthUserStore, ["login"]),

    async submitLogin() {
      this.isLoading = true;
      try {
        await axios.get("/sanctum/csrf-cookie");

        const response = await axios.post("/login", {
          email: this.email,
          password: this.password,
        });

        if (response.status === 204) {
          await this.login();
          this.$router.push({ name: "Overview" });
        }
      } catch (error) {
        console.error("Error during login:", error);
        this.alertMessage =
          error.response?.data?.message ||
          "Ocurrió un error durante el inicio de sesión.";
        this.alertType = "alert-danger";
      } finally {
        this.isLoading = false;
        setTimeout(() => {
          this.alertMessage = "";
          this.alertType = "";
        }, 3000);
      }
    },
  },
};
</script>
