<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            title="Editar Granja"
            :sub-title="'Modificar datos de la granja'"
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6 ml-n2">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <base-input
                        v-model="datosGranja.nombre"
                        type="text"
                        label="Nombre"
                        placeholder="Nombre"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Ubicación"
                      rules="required"
                    >
                      <base-input
                        v-model="datosGranja.ubicacion"
                        type="text"
                        label="Ubicación"
                        placeholder="Ubicación"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Altura sobre nivel del mar"
                      rules="required"
                    >
                      <base-input label="Altura">
                        <select
                          v-model="datosGranja.altura_id"
                          class="custom-select"
                          @change="actualizarClima"
                        >
                          <option value="">(Seleccionar Altura)</option>
                          <option
                            v-for="altura in alturas"
                            :key="altura.id"
                            :value="altura.id"
                          >
                            {{ altura.altura }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Clima"
                    >
                      <base-input
                        v-model="datosGranja.tipo_clima"
                        type="text"
                        label="Tipo de Clima"
                        placeholder="Clima (ej. Caliente)"
                        :readonly="true"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Programa de Oscurecimiento"
                      rules="required"
                    >
                      <base-input label="Programa Oscurecimiento">
                        <select
                          v-model="datosGranja.programaOscurecimiento_id"
                          class="custom-select"
                        >
                          <option value="">
                            (Seleccionar Programa Oscurecimiento)
                          </option>
                          <option
                            v-for="programa in programaOscurecimientos"
                            :key="programa.id"
                            :value="programa.id"
                          >
                            {{ programa.programa_oscurecimiento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Alojamiento"
                      rules="required"
                    >
                      <base-input label="Alojamientos">
                        <select
                          v-model="datosGranja.alojamiento_id"
                          class="custom-select"
                        >
                          <option value="">(Seleccionar Alojamiento)</option>
                          <option
                            v-for="alojamiento in alojamientos"
                            :key="alojamiento.id"
                            :value="alojamiento.id"
                          >
                            {{ alojamiento.alojamiento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Comederos"
                      rules="required"
                    >
                      <base-input label="Comederos">
                        <select
                          v-model="datosGranja.comedero_id"
                          class="custom-select"
                        >
                          <option value="">(Seleccionar Comedero)</option>
                          <option
                            v-for="comedero in comederos"
                            :key="comedero.id"
                            :value="comedero.id"
                          >
                            {{ comedero.comederos }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <hr />
                <div class="mb-3">
                  <div class="align-items-center">
                    <h3>Galpones</h3>
                    <button
                      v-if="!hasProcessedLote"
                      class="btn btn-primary"
                      @click="agregarGalpon"
                    >
                      Agregar Galpón
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Numero</th>
                        <th scope="col">Secciones</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(galpon, index) in datosGranja.galpones"
                        :key="index"
                      >
                        <td>
                          <span>{{ index + 1 }}</span>
                        </td>
                        <td>
                          <ValidationProvider
                            v-slot="{ errors }"
                            :name="'Sección ' + (index + 1)"
                            rules="required|numeric|min_value:1"
                          >
                            <base-input
                              v-model="galpon.secciones"
                              type="number"
                              :label="'Secciones Galpon ' + (index + 1)"
                              placeholder="Cantidad de secciones galpon"
                              :disabled="hasProcessedLote"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <button
                            style="margin-top: 23px"
                            class="btn btn-danger"
                            @click.prevent="eliminarGalpon(index)"
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import Swal from "sweetalert2";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

export default {
  name: "EditarGranja",
  components: { Card, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      datosGranja: {
        nombre: "",
        ubicacion: "",
        secciones: [],
        galpones: [],
        altura_id: "",
        programaOscurecimiento_id: "",
        alojamiento_id: "",
        comedero_id: "",
      },
      alturas: [],
      lotes: [],
      programaOscurecimientos: [],
      comederos: [],
      alojamientos: [],
      loading: false,
    };
  },

  computed: {
    hasProcessedLote() {
      return (
        this.datosGranja &&
        Array.isArray(this.datosGranja.lotes) &&
        this.datosGranja.lotes.some((lote) => lote.activo === true)
      );
    },
  },

  watch: {
    "datosGranja.altura_id": "actualizarClima",
  },
  mounted() {
    this.cargarGranja();
    this.CargarDatos();
  },
  methods: {
    agregarGalpon() {
      this.datosGranja.galpones.push({
        secciones: "",
      });
    },

    async cargarGranja() {
      this.loading = true;
      try {
        const { data } = await axios.get(`/api/granja/${this.id}`);
        this.datosGranja = data;
      } catch (error) {
        console.error("Error al cargar la granja:", error);
        alert("No se pudo cargar la granja. Por favor, intenta nuevamente.");
      } finally {
        this.loading = false;
      }
    },

    async CargarDatos() {
      try {
        const { data } = await axios.get("/api/granja/datos-granja");
        this.programaOscurecimientos = data.programa_oscurecimiento;
        this.alturas = data.alturas;
        this.alojamientos = data.tipos_alojamiento;
        this.comederos = data.tipos_comedero;
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        alert(
          "No se pudieron cargar los datos. Por favor, intenta nuevamente.",
        );
      }
    },

    actualizarClima() {
      const alturaSeleccionada = this.alturas.find(
        (altura) => altura.id === this.datosGranja.altura_id,
      );
      if (alturaSeleccionada) {
        this.datosGranja.tipo_clima = alturaSeleccionada.tipo_clima;
      }
    },

    eliminarGalpon(index) {
      const hasLotes =
        this.datosGranja.lotes &&
        this.datosGranja.lotes.some(
          (lote) => lote.granja_id === this.datosGranja.id,
        );

      if (hasLotes) {
        Swal.fire(
          "Error",
          "No se puede eliminar el galpón porque la granja tiene lotes asociados.",
          "error",
        );
        return;
      }
      this.datosGranja.galpones.splice(index, 1);
    },

    async guardar() {
      this.loading = true;
      try {
        const data = {
          nombre: this.datosGranja.nombre,
          ubicacion: this.datosGranja.ubicacion,
          secciones: this.datosGranja.galpones.map((g) => g.secciones),
          altura_id: this.datosGranja.altura_id,
          programaOscurecimiento_id: this.datosGranja.programaOscurecimiento_id,
          alojamiento_id: this.datosGranja.alojamiento_id,
          comedero_id: this.datosGranja.comedero_id,
        };
        await axios.put(`/api/granja/${this.id}`, data);
        Swal.fire({
          icon: "success",
          title: "Éxito",
          text: "Granja actualizada correctamente.",
          confirmButtonText: "Aceptar",
        }).then(() => {
          this.$router.push({ name: "Granja" });
        });
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
