<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <p class="card-title m-0 pt-2">
                Usuarios del Cliente: {{ nombreCliente }}
              </p>
              <div class="d-flex justify-content-between">
                <div>
                  <button
                    class="btn btn-primary btn-fill mr-2"
                    @click="handleCrearUsuario"
                  >
                    Crear nuevo usuario
                  </button>
                </div>
              </div>
            </template>
            <div
              v-if="usuarios.length"
              class="table-hover table-striped table-bordered"
            >
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Correo</th>
                    <th class="text-center">Rol</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usuario in usuarios" :key="usuario.id">
                    <td>{{ usuario.name }}</td>
                    <td>{{ usuario.email }}</td>
                    <td>{{ usuario.rol.Nombre }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-warning btn-sm mr-2"
                        @click="editarUsuario(usuario.id)"
                      >
                        Editar
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="eliminarUsuario(usuario.id)"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="clearfix" />
            </div>
            <div v-else class="text-center">
              <p>No hay usuarios disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import Swal from "sweetalert2";

export default {
  components: { Card },
  data() {
    return {
      usuarios: [],
      nombreCliente: "",
    };
  },
  mounted() {
    this.cargarUsuarios();
  },
  methods: {
    async cargarUsuarios() {
      this.loading = true;
      try {
        const clienteId = this.$route.params.id;
        const { data } = await axios.get(`/api/user/usuarios/${clienteId}`);
        this.usuarios = data.users;
        this.nombreCliente = data.nombre;
      } catch (error) {
        alert(
          "No se pudieron cargar los usuarios. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },
    handleCrearUsuario() {
      const clienteId = this.$route.params.id;
      this.$router.push({
        name: "CrearUsuario",
        params: { id: clienteId },
      });
    },
    editarUsuario(usuarioId) {
      this.$router.push({
        name: "EditarUsuario",
        params: { usuarioId },
      });
    },
    async eliminarUsuario(usuarioId) {
      try {
        await axios.delete(`/api/user/${usuarioId}`);

        await Swal.fire({
          icon: "success",
          title: "Usuario eliminado",
          text: "El usuario ha sido eliminado exitosamente.",
          confirmButtonText: "Aceptar",
        });

        this.usuarios = this.usuarios.filter(
          (usuario) => usuario.id !== usuarioId,
        );
      } catch (error) {
        console.error("Hubo un error al eliminar el usuario:", error);
        alert("No se pudo eliminar el usuario. Por favor, intenta nuevamente.");
      }
    },
  },
};
</script>
