<template>
  <div v-if="granja" class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Detalles de la Granja</h4>
            </template>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Nombre</th>
                    <td>{{ granja.nombre }}</td>
                  </tr>
                  <tr>
                    <th>Ubicación</th>
                    <td>{{ granja.ubicacion }}</td>
                  </tr>
                  <tr>
                    <th>Altura sobre nivel del mar</th>
                    <td>{{ granja.alturas.altura }}</td>
                  </tr>
                  <tr>
                    <th>Tipo de clima</th>
                    <td>{{ granja.alturas.tipo_clima }}</td>
                  </tr>
                  <tr>
                    <th>Programa de Oscurecimiento</th>
                    <td>
                      {{
                        granja.programa_oscurecimiento.programa_oscurecimiento
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Tipo de Alojamiento</th>
                    <td>{{ granja.alojamiento.alojamiento }}</td>
                  </tr>
                  <tr>
                    <th>Tipo de Comedero</th>
                    <td>{{ granja.comedero.comederos }}</td>
                  </tr>
                  <tr>
                    <th>Detalles Galpones</th>
                    <td>
                      <ul class="list-unstyled">
                        <li v-for="galpon in granja.galpones" :key="galpon.id">
                          Galpon {{ galpon.numero }} - Secciones
                          {{ galpon.secciones }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <p>Cargando detalles de la granja...</p>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      granja: null,
    };
  },
  mounted() {
    this.cargarGranja();
  },
  methods: {
    async cargarGranja() {
      try {
        const { data } = await axios.get(
          `/api/granja/${this.$route.params.id}`,
        );
        this.granja = data;
      } catch (error) {
        console.error("Hubo un error al obtener la granja:", error);
      }
    },
  },
};
</script>
