import VueRouter from "vue-router";
import routes from "./routes/routes";
import { useAuthUserStore } from "./stores/auth.store";

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "nav-item active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthUserStore();

  if (to.name === "Login") {
    if (store.authenticated) {
      next({
        name: "Overview",
      });
    } else {
      next();
    }
    return;
  }

  try {
    const loggedIn = store.authenticated || (await store.login());
    if (loggedIn) {
      if (store.isRoot) {
        next();
      } else {
        if (to.name && to.name.startsWith("Client")) {
          next({ name: "Overview" });
        } else {
          next();
        }
      }
    } else {
      next({ name: "Login" });
    }
  } catch (error) {
    next({ name: "Login" });
  }
});

export default router;
