<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Lista de Granjas</h4>
              <div class="d-flex justify-content-between">
                <div>
                  <button
                    class="btn btn-primary btn-fill mr-2"
                    @click="handleCrear"
                  >
                    Crear Granja
                  </button>
                </div>
              </div>
            </template>
            <div
              v-if="granjas.length"
              class="table-hover table-striped table-bordered"
            >
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-center">N°</th>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Ubicación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="granja in granjas" :key="granja.id">
                    <td>{{ granja.id }}</td>
                    <td>{{ granja.nombre }}</td>
                    <td>{{ granja.ubicacion }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary btn-sm mr-2"
                        @click="verGranja(granja.id)"
                      >
                        Ver
                      </button>
                      <button
                        class="btn btn-warning btn-sm mr-2"
                        @click="editarGranja(granja.id)"
                      >
                        Editar
                      </button>
                      <button
                        v-if="granjas.length > 1"
                        class="btn btn-success btn-sm"
                        :disabled="granja.id === granjaSeleccionadaId"
                        @click="confirmarSeleccion(granja)"
                      >
                        {{
                          granja.id === granjaSeleccionadaId
                            ? "Seleccionada"
                            : "Seleccionar"
                        }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="clearfix" />
            </div>
            <div v-else class="text-center">
              <p>No hay granjas disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import Swal from "sweetalert2";
import { useAuthUserStore } from "../stores/auth.store";
import { mapState } from "pinia";

export default {
  components: { Card },
  data() {
    return {
      granjas: [],
    };
  },
  computed: {
    ...mapState(useAuthUserStore, ["user"]),
    granjaSeleccionadaId() {
      return this.user.granja?.id;
    },
  },
  mounted() {
    this.cargarGranjas();
  },
  methods: {
    async cargarGranjas() {
      try {
        const { data } = await axios.get("/api/granja");
        this.granjas = data;
      } catch (error) {
        console.error("Hubo un error al obtener las granjas:", error);
      }
    },

    confirmarSeleccion(granja) {
      Swal.fire({
        title: `¿Desea asociar la granja "${granja.nombre}" a su usuario?`,
        text: "Esta acción cambiará la granja asociada a su cuenta.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, seleccionar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.seleccionarGranja(granja.id);
        }
      });
    },

    async seleccionarGranja(granjaId) {
      try {
        const { data } = await axios.put("/api/granja/cambiar-granja", {
          granja_id: granjaId,
        });
        const authUserStore = useAuthUserStore();
        authUserStore.setUserGranja(data.granja);
        Swal.fire(
          "¡Seleccionado!",
          `La granja ha sido asociada a su usuario.`,
          "success",
        );
        this.granjaSeleccionadaId = data.granja.id;
      } catch (error) {
        console.error("Hubo un error al seleccionar la granja:", error);
        Swal.fire(
          "Error",
          "Hubo un problema al seleccionar la granja.",
          "error",
        );
      }
    },

    handleCrear() {
      this.$router.push({
        name: "CrearGranja",
      });
    },
    verGranja(granjaId) {
      this.$router.push({
        name: "DetalleGranja",
        params: { id: granjaId },
      });
    },
    editarGranja(granjaId) {
      this.$router.push({
        name: "EditarGranja",
        params: { id: granjaId },
      });
    },
  },
};
</script>

<style scoped>
.btn:disabled {
  border-color: #87cb16;
  color: #87cb16;
}
</style>
