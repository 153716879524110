<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Información Huevo Clasificado</h4>
              <div>
                <div class="d-flex justify-content-between">
                  <div></div>
                  <div class="d-flex">
                    <select
                      v-model.number="selectedGalpon"
                      class="custom-select custom-select-lg mr-2"
                      style="width: 130px"
                      @change="handleGalponChange"
                    >
                      <option value="-1">General</option>
                      <option
                        v-for="galpon in galpones"
                        :key="galpon.id"
                        :value="galpon.id"
                      >
                        Galpon {{ galpon.numero }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-if="datosTabla.datosSemana"
              class="table-hover table-striped table-bordered"
              style="overflow-x: auto"
            >
              <div class="table-container">
                <table class="table table-sm">
                  <thead>
                    <th class="text-center" style="width: 84px">Sem</th>
                    <th class="text-center">Dia</th>
                    <th class="text-center">Postura<br />AAAA</th>
                    <th class="text-center">Postura<br />AAA</th>
                    <th class="text-center">Postura<br />AA</th>
                    <th class="text-center">Postura<br />A</th>
                    <th class="text-center">Postura<br />B</th>
                    <th class="text-center">Postura<br />C</th>
                    <th class="text-center">Postura<br />D</th>
                    <th class="text-center">Postura<br />Yema</th>
                    <th class="text-center">postura<br />Roto</th>
                    <th class="text-center">Postura<br />Sucio</th>
                    <th class="text-center">Postura<br />Total</th>
                  </thead>
                  <tbody v-if="!datosTabla.cargando">
                    <template v-if="datosTabla.datosSemana.length > 0">
                      <template v-for="semana in datosTabla.datosSemana">
                        <tr
                          v-for="(diaSemana, index) in semana.dias"
                          :key="diaSemana.id"
                        >
                          <td v-if="index == 0" rowspan="9" align="center">
                            <span class="h2">{{ semana.numero }}</span>
                          </td>
                          <td align="center" style="padding-left: 6px">
                            {{ diaSemana.nombre }} <br />
                            {{ diaSemana.fecha }}
                          </td>

                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_aaaa,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_aaa,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_aa,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_a,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_b,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_c,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_d,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_yema,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_roto,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">
                            {{
                              calcularPorcentaje(
                                diaSemana.postura_sucio,
                                diaSemana.postura_total,
                              )
                            }}
                          </td>
                          <td align="center">{{ diaSemana.postura_total }}</td>
                        </tr>
                        <template v-if="semana.numero !== 0">
                          <tr :key="'tot-' + semana.id">
                            <td class="font-weight-bold" align="center">
                              Cant. Sem
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aaaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_a }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_b }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_c }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_d }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_yema }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_roto }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_sucio }}
                            </td>
                            <td
                              class="font-weight-bold table-cell-auto"
                              rowspan="2"
                              align="center"
                            >
                              {{ semana.totales.postura.postura_total }}
                            </td>
                          </tr>
                          <tr :key="'acum-' + semana.id">
                            <td class="font-weight-bold" align="center">
                              Part %
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_aaaa,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_aaa,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_aa,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_a,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_b,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_c,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_d,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_yema,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_roto,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{
                                calcularPorcentaje(
                                  semana.totales.postura.postura_sucio,
                                  semana.totales.postura.postura_total,
                                )
                              }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-if="datosTabla.cargando" class="text-center my-4">
                <p class="text-muted">Cargando tabla...</p>
              </div>
            </div>
            <div v-else class="text-center">
              <p>No hay datos disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      datosTabla: {
        datosSemana: [],
        cargando: false,
      },
      galpones: [],
      selectedGalpon: -1,
    };
  },
  mounted() {
    this.cargarDatos();
    this.cargarGalpones();
  },
  methods: {
    async cargarDatos() {
      let galponToRequest = "";
      if (this.selectedGalpon !== -1) {
        const galpon = this.galpones.find(
          (galpon) => galpon.id === this.selectedGalpon,
        );
        if (galpon && galpon.lotes?.length > 0) {
          galponToRequest = galpon.lotes.at(0).pivot.id;
        }
      }

      this.datosTabla.cargando = true;
      const { data } = await axios.get(
        "/api/levante/tabla/" + galponToRequest,
        {
          params: {
            tipo: "postura",
          },
        },
      );
      this.datosTabla = data;
      this.datosTabla.cargando = false;
    },
    async cargarGalpones() {
      const { data } = await axios.get("/api/galpon");
      this.galpones = data;
    },
    handleGalponChange() {
      this.cargarDatos();
    },
    calcularPorcentaje(posturaIndividual, posturaTotal) {
      if (posturaTotal > 0) {
        return ((posturaIndividual / posturaTotal) * 100).toFixed(2) + "%";
      }
      return "0%";
    },
  },
};
</script>

<style scoped>
.card .table tbody td:last-child {
  display: table-cell;
  width: auto;
  padding-right: 6px;
}

.card .table thead th:last-child {
  display: table-cell;
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.table-sm td,
.table-sm th {
  padding: 0.1rem 0.2rem;
}
</style>
