<template>
  <ul class="nav nav-mobile-menu">
    <li class="nav-item">
      <span class="nav-link"> {{ userName }} - {{ nombreCliente }} </span>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" @click.prevent="handleLogout">
        <span class="no-icon">Cerrar Sesión</span>
      </a>
    </li>
  </ul>
</template>
<script>
import axios from "axios";
import { useAuthUserStore } from "../stores/auth.store";
import { mapState, mapActions } from "pinia";
export default {
  name: "MobileMenu",
  computed: {
    ...mapState(useAuthUserStore, ["user"]),
    userName() {
      return this.user.name || "Usuario";
    },
    nombreCliente() {
      return this.user.cliente.nombre || "No disponible";
    },
  },
  methods: {
    ...mapActions(useAuthUserStore, ["logout"]),
    async handleLogout() {
      await axios.post("/logout");
      this.logout();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style></style>
