<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div v-if="$slots.image" class="card-image">
      <slot name="image" />
    </div>
    <div
      v-if="$slots.header || title"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <p v-if="subTitle" class="card-category">
          {{ subTitle }}
        </p>
      </slot>
    </div>
    <div v-if="$slots.default" class="card-body" :class="bodyClasses">
      <slot />
    </div>
    <slot name="raw-content" />
    <div v-if="$slots.footer" class="card-footer" :class="footerClasses">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    headerClasses: {
      type: [String, Object, Array],
      default: "",
    },
    bodyClasses: {
      type: [String, Object, Array],
      default: "",
    },
    footerClasses: {
      type: [String, Object, Array],
      default: "",
    },
  },
};
</script>
<style></style>
