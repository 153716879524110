<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">100 Awesome Nucleo Icons</h4>
              <p class="card-category">
                Handcrafted by our friends from
                <a target="_blank" href="https://nucleoapp.com/?ref=1712"
                  >NucleoApp</a
                >
              </p>
            </template>
            <div class="all-icons">
              <div class="row">
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-air-baloon" />
                    <p>nc-air-baloon</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-album-2" />
                    <p>nc-album-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-alien-33" />
                    <p>nc-alien-33</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-align-center" />
                    <p>nc-align-center</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-align-left-2" />
                    <p>nc-align-left-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-ambulance" />
                    <p>nc-ambulance</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-android" />
                    <p>nc-android</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-app" />
                    <p>nc-app</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-apple" />
                    <p>nc-apple</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-atom" />
                    <p>nc-atom</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-attach-87" />
                    <p>nc-attach-87</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-audio-92" />
                    <p>nc-audio-92</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-backpack" />
                    <p>nc-backpack</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-badge" />
                    <p>nc-badge</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bag" />
                    <p>nc-bag</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bank" />
                    <p>nc-bank</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-battery-81" />
                    <p>nc-battery-81</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bell-55" />
                    <p>nc-bell-55</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bold" />
                    <p>nc-bold</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bulb-63" />
                    <p>nc-bulb-63</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bullet-list-67" />
                    <p>nc-bullet-list-67</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-bus-front-12" />
                    <p>nc-bus-front-12</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-button-pause" />
                    <p>nc-button-pause</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-button-play" />
                    <p>nc-button-play</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-button-power" />
                    <p>nc-button-power</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-camera-20" />
                    <p>nc-camera-20</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-caps-small" />
                    <p>nc-caps-small</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-cart-simple" />
                    <p>nc-cart-simple</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-cctv" />
                    <p>nc-cctv</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-chart-bar-32" />
                    <p>nc-chart-bar-32</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-chart-pie-35" />
                    <p>nc-chart-pie-35</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-chart-pie-36" />
                    <p>nc-chart-pie-36</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-chart" />
                    <p>nc-chart</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-chat-round" />
                    <p>nc-chat-round</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-check-2" />
                    <p>nc-check-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-circle-09" />
                    <p>nc-circle-09</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-circle" />
                    <p>nc-circle</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-cloud-download-93" />
                    <p>nc-cloud-download-93</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-cloud-upload-94" />
                    <p>nc-cloud-upload-94</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-compass-05" />
                    <p>nc-compass-05</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-controller-modern" />
                    <p>nc-controller-modern</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-credit-card" />
                    <p>nc-credit-card</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-delivery-fast" />
                    <p>nc-delivery-fast</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-email-83" />
                    <p>nc-email-83</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-email-85" />
                    <p>nc-email-85</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-explore-2" />
                    <p>nc-explore-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-fav-remove" />
                    <p>nc-fav-remove</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-favourite-28" />
                    <p>nc-favourite-28</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-globe-2" />
                    <p>nc-globe-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-grid-45" />
                    <p>nc-grid-45</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-headphones-2" />
                    <p>nc-headphones-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-html5" />
                    <p>nc-html5</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-istanbul" />
                    <p>nc-istanbul</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-key-25" />
                    <p>nc-key-25</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-layers-3" />
                    <p>nc-layers-3</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-light-3" />
                    <p>nc-light-3</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-lock-circle-open" />
                    <p>nc-lock-circle-open</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-map-big" />
                    <p>nc-map-big</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-mobile" />
                    <p>nc-mobile</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-money-coins" />
                    <p>nc-money-coins</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-note-03" />
                    <p>nc-note-03</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-notes" />
                    <p>nc-notes</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-notification-70" />
                    <p>nc-notification-70</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-palette" />
                    <p>nc-palette</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-paper-2" />
                    <p>nc-paper-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-pin-3" />
                    <p>nc-pin-3</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-planet" />
                    <p>nc-planet</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-preferences-circle-rotate" />
                    <p>nc-preferences-circle-rotate</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-puzzle-10" />
                    <p>nc-puzzle-10</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-quote" />
                    <p>nc-quote</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-refresh-02" />
                    <p>nc-refresh-02</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-ruler-pencil" />
                    <p>nc-ruler-pencil</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-satisfied" />
                    <p>nc-satisfied</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-scissors" />
                    <p>nc-scissors</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-send" />
                    <p>nc-send</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-settings-90" />
                    <p>nc-settings-90</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-settings-gear-64" />
                    <p>nc-settings-gear-64</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-settings-tool-66" />
                    <p>nc-settings-tool-66</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-simple-add" />
                    <p>nc-simple-add</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-simple-delete" />
                    <p>nc-simple-delete</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-simple-remove" />
                    <p>nc-simple-remove</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-single-02" />
                    <p>nc-single-02</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-single-copy-04" />
                    <p>nc-single-copy-04</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-spaceship" />
                    <p>nc-spaceship</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-square-pin" />
                    <p>nc-square-pin</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-stre-down" />
                    <p>nc-stre-down</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-stre-left" />
                    <p>nc-stre-left</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-stre-right" />
                    <p>nc-stre-right</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-stre-up" />
                    <p>nc-stre-up</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-sun-fog-29" />
                    <p>nc-sun-fog-29</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-support-17" />
                    <p>nc-support-17</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-tablet-2" />
                    <p>nc-tablet-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-tag-content" />
                    <p>nc-tag-content</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-tap-01" />
                    <p>nc-tap-01</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-time-alarm" />
                    <p>nc-time-alarm</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-tv-2" />
                    <p>nc-tv-2</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-umbrella-13" />
                    <p>nc-umbrella-13</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-vector" />
                    <p>nc-vector</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-watch-time" />
                    <p>nc-watch-time</p>
                  </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                  <div class="font-icon-detail">
                    <i class="nc-icon nc-zoom-split" />
                    <p>nc-zoom-split</p>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
export default {
  components: {
    Card,
  },
};
</script>
<style></style>
