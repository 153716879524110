import axios from "axios";
import { defineStore } from "pinia";

export const useAuthUserStore = defineStore("authUser", {
  state: () => ({
    authenticated: false,
    user: {},
  }),
  actions: {
    async login() {
      try {
        const { data } = await axios.get("/api/user");
        this.user = data;
        this.authenticated = true;
        return true;
      } catch (error) {
        this.user = {};
        this.authenticated = false;
        return false;
      }
    },
    logout() {
      this.$reset();
    },
    setUserGranja(granja) {
      this.user.granja = granja;
    },
  },
  getters: {
    isRoot(state) {
      return state.user.rol && state.user.rol.codigo === "ROOT";
    },
    isAdmin(state) {
      return state.user.rol && state.user.rol.codigo === "ADMINISTRATOR";
    },
    isOperario(state) {
      return state.user.rol && state.user.rol.codigo === "OPERATOR";
    },
    isAdminOrRoot() {
      return this.isRoot || this.isAdmin;
    },
  },
});
