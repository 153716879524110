<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card title="Editar Lote" :sub-title="'Modificar datos del lote'">
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Fecha de Ingreso"
                    >
                      <base-input
                        v-model="datosLote.fecha_ingreso"
                        type="date"
                        label="Fecha de Ingreso"
                        :readonly="true"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider v-slot="{ errors }" name="Cantidad">
                      <base-input
                        v-model="datosLote.cantidad"
                        type="number"
                        label="Cantidad"
                        placeholder="Cantidad"
                        :readonly="true"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Edad Despique"
                      rules="required|numeric|min_value:1"
                    >
                      <base-input
                        v-model="datosLote.edad_despique"
                        type="number"
                        label="Edad Despique"
                        placeholder="Edad Despique"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider v-slot="{ errors }" name="Estirpe">
                      <base-input label="Estirpe">
                        <select
                          v-model="datosLote.estirpe_id"
                          class="custom-select"
                          disabled
                        >
                          <option value="-1">(Seleccionar Estirpe)</option>
                          <option
                            v-for="estirpe in estirpes"
                            :key="estirpe.id"
                            :value="estirpe.id"
                          >
                            {{ estirpe.estirpe }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4" style="margin: 12px">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Edad Producción"
                      rules="required|numeric|min_value:1"
                    >
                      <base-input
                        v-model="datosLote.edad_produccion"
                        type="number"
                        label="Edad Producción"
                        placeholder="Edad Producción"
                        disabled
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("not_selected", {
  validate: (value) => value !== "-1",
  message: "Debe seleccionar una opción válida.",
});

localize("es", es);

export default {
  name: "EditarLote",
  components: { Card, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      datosLote: {
        fecha_ingreso: "",
        cantidad: undefined,
        edad_despique: undefined,
        estirpe_id: -1,
        galpon_id: -1,
        edad_produccion: undefined,
      },
      estirpes: [],
      galpones: [],
      loading: false,
    };
  },
  mounted() {
    this.cargarEstirpes();
    this.cargarLote();
  },
  methods: {
    async cargarEstirpes() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/estirpe");
        this.estirpes = data;
      } catch (error) {
        console.error("Error al cargar estirpes:", error);
        alert(
          "No se pudieron cargar los estirpes. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },
    async cargarLote() {
      this.loading = true;
      try {
        const { data } = await axios.get(`/api/lote/lotes/${this.id}`);
        this.datosLote = data;
      } catch (error) {
        console.error("Error al cargar el lote:", error);
        alert("No se pudo cargar el lote. Por favor, intenta nuevamente.");
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        const data = {
          edad_despique: this.datosLote.edad_despique,
          edad_produccion: this.datosLote.edad_produccion,
        };
        await axios.put(`/api/lote/lote/${this.id}`, data);
        this.$router.push({ name: "Lote" });
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        alert(
          "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
