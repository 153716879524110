<template>
  <div class="d-flex flex-column">
    <b-modal
      id="myModal"
      ref="myModal"
      :title="modalTitle"
      @hidden="resetModal"
    >
      <div class="d-flex flex-column align-items-center">
        <p>Aves</p>
        <div class="tables-container">
          <b-table
            v-if="tableData.length > 0"
            striped
            hover
            :items="tableData"
            :fields="fields"
            class="mb-4"
          >
            <template #cell(peso)="data">
              {{ data.item.peso }}
            </template>
            <template #cell(cantidad)="data">
              {{ data.item.cantidad }}
            </template>
          </b-table>
          <p v-else>No hay datos disponibles</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedData: {
      type: Object,
      default: null,
    },
    galpon: {
      type: [Object, Number],
      default: null,
    },
    maxItemsPerTable: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      modalTitle: "",
      fields: [
        { key: "peso", label: "Peso" },
        { key: "cantidad", label: "Cantidad" },
      ],
      tableData: [],
    };
  },
  watch: {
    selectedData: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.peso_dato) {
          this.fetchAves(newValue.peso_dato.id);
        }
      },
    },
  },
  methods: {
    resetModal() {
      this.modalTitle = "";
      this.tableData = [];
    },
    async fetchAves(pesoDatoId) {
      try {
        const response = await axios.get(
          `/api/peso/porIntervalo/${pesoDatoId}`,
        );
        const pesoData = response.data;

        this.tableData = pesoData || [];

        const semanaNumero = this.selectedData
          ? this.selectedData.numero === 0
            ? "Día 1"
            : `Semana: ${this.selectedData.numero}`
          : "Desconocida";
        const galponNumero = this.galpon?.numero || "General";

        this.modalTitle = `Pesajes galpon N°. ${galponNumero} - ${semanaNumero}`;
      } catch (error) {
        console.error("Error fetching aves:", error);
        this.modalTitle = "Error cargando los datos";
      }
    },
  },
};
</script>

<style scoped>
.tables-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  max-width: 100%;
}

.tables-container b-table {
  flex: 1 0 auto;
  min-width: 300px;
}
</style>
