<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            title="Ingreso Creacion/Cliente"
            :sub-title="'Ingreso de datos para el cliente'"
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="nombre"
                      rules="required|string|max:255"
                    >
                      <base-input
                        v-model="datosCliente.nombre"
                        type="string"
                        label="Nombre"
                        placeholder="nombre"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="identificacion"
                      rules="required|string|max:255"
                    >
                      <base-input
                        v-model="datosCliente.identificacion"
                        type="string"
                        label="Identificacion"
                        placeholder="identificacion"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="correo"
                      rules="required|email|max:255"
                    >
                      <base-input
                        v-model="datosCliente.correo"
                        type="email"
                        label="Correo"
                        placeholder="correo"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="telefono"
                      rules="required|string|max:20"
                    >
                      <base-input
                        v-model="datosCliente.telefono"
                        type="string"
                        label="Telefono"
                        placeholder="telefono"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="direccion"
                      rules="required|string|max:255"
                    >
                      <base-input
                        v-model="datosCliente.direccion"
                        type="string"
                        label="Direccion"
                        placeholder="direccion"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="razon_social"
                      rules="required|string|max:255"
                    >
                      <base-input
                        v-model="datosCliente.razon_social"
                        type="string"
                        label="Razon Social"
                        placeholder="Razon Social"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import Swal from "sweetalert2";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

export default {
  name: "CrearCliente",
  components: { Card, ValidationProvider, ValidationObserver },
  data() {
    return {
      datosCliente: {
        nombre: "",
        identificacion: "",
        correo: "",
        telefono: "",
        direccion: "",
        razon_social: "",
      },
      loading: false,
    };
  },
  methods: {
    async guardar() {
      this.loading = true;
      try {
        const data = {
          nombre: this.datosCliente.nombre,
          identificacion: this.datosCliente.identificacion,
          correo: this.datosCliente.correo,
          telefono: this.datosCliente.telefono,
          direccion: this.datosCliente.direccion,
          razon_social: this.datosCliente.razon_social,
        };
        await axios.post("/api/cliente", data);
        await Swal.fire({
          title: "Éxito",
          text: "Cliente creado correctamente.",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        this.$router.push({ name: "Cliente" });
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.correo
        ) {
          const errorMessage = error.response.data.errors.correo[0];

          if (errorMessage === "validation.unique") {
            Swal.fire({
              title: "Error",
              text: "El correo electrónico ya existe. Por favor, elige otro.",
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
              icon: "error",
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
