<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Información Pesaje</h4>
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      class="btn btn-primary btn-fill mr-2"
                      :disabled="selectedGalpon == -1"
                      @click="handleIngresar"
                    >
                      Ingresar
                    </button>
                  </div>
                  <div>
                    <select
                      v-model.number="selectedGalpon"
                      class="custom-select custom-select-lg"
                      @change="handleGalponChange"
                    >
                      <option value="-1">General</option>
                      <option
                        v-for="galpon in galpones"
                        :key="galpon.id"
                        :value="galpon"
                      >
                        Galpon {{ galpon.numero }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="datosTabla.cargando" class="text-center">
              <span>Cargando...</span>
            </div>
            <div
              v-else-if="
                datosTabla.datosSemana && datosTabla.datosSemana.length > 0
              "
              class="table-hover table-striped table-bordered"
            >
              <table class="table table-sm table-responsive-sm">
                <thead>
                  <tr>
                    <th class="text-center" title="Semana">Sem</th>
                    <th class="text-center" title="Fecha Pesaje">Fecha</th>
                    <th class="text-center" title="Intervalo">Inter</th>
                    <th class="text-center" title="Peso Tabla">Tabla</th>
                    <th class="text-center" title="Peso Promedio">Promedio</th>
                    <th class="text-center" title="Cumplimiento Peso">
                      Cumplto
                    </th>
                    <th class="text-center" title="Uniformidad">Uniform</th>
                    <th class="text-center" title="Bajo Peso">Bajo Peso</th>
                    <th class="text-center" title="Cabeza Lote">Cabeza</th>
                    <th class="text-center" title="Variación">Variación</th>
                    <th class="text-center" title="Proyección Livianas">
                      Livianas
                    </th>
                    <th class="text-center" title="Proyección Grandes">
                      Grandes
                    </th>
                    <th class="text-center" title="Peso Mínimo">Mín</th>
                    <th class="text-center" title="Peso Máximo">Máx</th>
                    <th class="text-center" title="Total Aves">Total</th>
                    <th class="text-center" title="Ver Pesajes">Pesajes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="semana in datosTabla.datosSemana" :key="semana.id">
                    <template v-if="hasCompleteData(semana.peso_dato)">
                      <td align="center">
                        {{ semana.numero === 0 ? "Día 1" : semana.numero }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.fecha_pesaje }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.intervalo }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.peso_tabla?.toFixed(1) || "N/A" }}
                        g
                      </td>
                      <td
                        align="center"
                        :class="{
                          highlight:
                            semana.peso_dato.peso_promedio <
                            semana.peso_dato.peso_tabla * 0.98,
                        }"
                      >
                        {{ semana.peso_dato.peso_promedio.toFixed(1) || "N/A" }}
                        g
                      </td>
                      <td
                        align="center"
                        :style="{
                          color:
                            semana.peso_dato.cumplimiento_peso >= 0
                              ? '#007bff'
                              : '#dc3545',
                        }"
                      >
                        {{
                          semana.peso_dato.cumplimiento_peso.toFixed(1) || "N/A"
                        }}
                        %
                      </td>
                      <td
                        align="center"
                        :class="{
                          'highlight-uniformidad':
                            semana.peso_dato.uniformidad < 80,
                        }"
                      >
                        {{ semana.peso_dato.uniformidad.toFixed(2) || "N/A" }}
                      </td>
                      <td
                        align="center"
                        :class="{
                          'highlight-bajo-peso':
                            semana.peso_dato.bajo_peso > valorReferencia,
                        }"
                      >
                        {{ semana.peso_dato.bajo_peso.toFixed(2) || "N/A" }}
                      </td>
                      <td
                        align="center"
                        :class="{
                          'highlight-bajo-peso':
                            semana.peso_dato.cabeza_lote > valorReferencia,
                        }"
                      >
                        {{ semana.peso_dato.cabeza_lote.toFixed(2) || "N/A" }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.variacion.toFixed(2) || "N/A" }}
                      </td>
                      <td align="center">
                        {{
                          semana.peso_dato.proyeccion_livianas.toFixed(2) ||
                          "N/A"
                        }}
                      </td>
                      <td align="center">
                        {{
                          semana.peso_dato.proyeccion_grandes.toFixed(2) ||
                          "N/A"
                        }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.peso_minimo || "N/A" }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.peso_maximo || "N/A" }}
                      </td>
                      <td align="center">
                        {{ semana.peso_dato.total_aves || "N/A" }}
                      </td>
                      <td
                        align="center"
                        style="display: table-cell; width: auto"
                      >
                        <button
                          class="btn btn-primary btn-sm mr-2"
                          @click="openModal(semana)"
                        >
                          Ver
                        </button>
                        <button
                          v-if="selectedGalpon !== -1"
                          class="btn btn-warning btn-sm"
                          @click="handleEditPesaje(semana.peso_dato.id)"
                        >
                          Editar
                        </button>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
              <div class="clearfix" />
            </div>
            <div v-else class="text-center">
              <p>No hay datos.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal-ver
      ref="modalVer"
      :selected-data="selectedData"
      :galpon="selectedGalpon"
    />
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import ModalVer from "../components/Modal/ModalPeso.vue";

export default {
  components: { Card, ModalVer },
  data() {
    return {
      datosTabla: {
        datosSemana: [],
        cargando: false,
      },
      galpones: [],
      selectedGalpon: -1,
      selectedData: null,
      valorReferencia: 100,
    };
  },
  mounted() {
    this.cargarDatos();
    this.cargarGalpones();
  },
  methods: {
    async cargarDatos(galponLoteId = -1) {
      this.datosTabla.cargando = true;
      const galponToRequest = galponLoteId !== -1 ? galponLoteId : "";
      const { data } = await axios.get("/api/peso/tabla/" + galponToRequest);
      this.datosTabla = data;
      this.datosTabla.cargando = false;
    },
    async cargarGalpones() {
      const { data } = await axios.get("/api/galpon");
      this.galpones = data;
    },
    handleGalponChange() {
      if (this.selectedGalpon === -1) {
        this.cargarDatos();
      } else {
        const galpon = this.galpones.find(
          (galpon) => galpon.id === this.selectedGalpon.id,
        );
        if (!galpon || !galpon?.lotes?.at(0)) {
          this.datosTabla.datosSemana = [];
          return;
        }
        this.cargarDatos(galpon.lotes.at(0).pivot.id);
      }
    },
    handleIngresar() {
      const galponLoteId = this.selectedGalpon.lotes[0]?.pivot.id.toString();
      this.$router.push({
        name: "CrearPesaje",
        params: { galponLoteId },
      });
    },
    handleEditPesaje(pesoId) {
      const galponLoteId = this.selectedGalpon.lotes[0]?.pivot.id.toString();
      this.$router.push({
        name: "CrearPesaje",
        params: { galponLoteId, pesoId },
      });
    },
    hasCompleteData(pesoDato) {
      return pesoDato && Object.keys(pesoDato).length > 0;
    },
    openModal(semana) {
      this.selectedData = semana;
      this.$refs.modalVer.$refs.myModal.show();
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: #f8d7da;
  color: #46090f;
}
.highlight-bajo-peso {
  background-color: #d4edda;
  color: #ed2626;
}
.highlight-uniformidad {
  background-color: #fff3cd;
  color: #856404;
}
</style>
