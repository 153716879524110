<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">&copy; Henhaus by Altaingenieria.</div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
