<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            title="Ingreso Creación/Usuario"
            :sub-title="'Ingreso de datos para usuario'"
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <base-input
                        v-model="datosUsuario.name"
                        type="text"
                        label="Nombre"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Correo"
                      rules="required|email"
                    >
                      <base-input
                        v-model="datosUsuario.email"
                        type="email"
                        label="Correo"
                        placeholder="Correo"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Contraseña"
                      rules="required|min:6"
                    >
                      <base-input
                        v-model="datosUsuario.password"
                        type="password"
                        label="Contraseña"
                        placeholder="Contraseña"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Confirmar Contraseña"
                      rules="required"
                    >
                      <base-input
                        v-model="datosUsuario.password_confirmation"
                        type="password"
                        label="Confirmar Contraseña"
                        placeholder="Confirmar Contraseña"
                        @input="validatePasswordConfirmation"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="passwordError" class="text-danger text-center">
                  {{ passwordError }}
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Rol"
                      rules="required"
                    >
                      <label for="rol">Rol</label>
                      <select
                        id="rol"
                        v-model="datosUsuario.rol_id"
                        class="form-control"
                      >
                        <option value="">Seleccione un rol</option>
                        <option
                          v-for="rol in filteredRoles"
                          :key="rol.id"
                          :value="rol.id"
                        >
                          {{ rol.Nombre }}
                        </option>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import Swal from "sweetalert2";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

export default {
  name: "CrearUsuario",
  components: { Card, ValidationProvider, ValidationObserver },
  data() {
    return {
      datosUsuario: {
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        rol_id: "",
      },
      loading: false,
      passwordError: "",
      roles: [],
    };
  },
  computed: {
    filteredRoles() {
      return this.roles.filter((rol) => rol.codigo !== "ROOT");
    },
  },
  async created() {
    try {
      const response = await axios.get("/api/user/roles");
      this.roles = response.data;
    } catch (error) {
      console.error("Error al cargar los roles:", error);
      Swal.fire({
        title: "Error",
        text: "No se pudieron cargar los roles. Por favor, intenta nuevamente.",
        icon: "error",
      });
    }
  },
  methods: {
    validatePasswordConfirmation() {
      if (
        this.datosUsuario.password !== this.datosUsuario.password_confirmation
      ) {
        this.passwordError = "Las contraseñas no coinciden.";
      } else {
        this.passwordError = "";
      }
    },
    async guardar() {
      if (this.passwordError) {
        return;
      }
      this.loading = true;
      try {
        const clienteId = this.$route.params.id;
        const data = {
          email: this.datosUsuario.email,
          password: this.datosUsuario.password,
          password_confirmation: this.datosUsuario.password_confirmation,
          name: this.datosUsuario.name,
          cliente_id: clienteId,
          rol_id: this.datosUsuario.rol_id,
        };
        await axios.post("/api/user/crear", data);
        await Swal.fire({
          title: "Éxito",
          text: "Usuario creado correctamente.",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        this.$router.push({ name: "Cliente" });
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.email
        ) {
          const errorMessage = error.response.data.errors.email[0];

          if (errorMessage === "validation.unique") {
            Swal.fire({
              title: "Error",
              text: "El correo electrónico ya existe. Por favor, elige otro.",
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
              icon: "error",
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
