<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Inventario Aves</h4>
              <div>
                <div class="d-flex justify-content-between">
                  <div></div>
                  <div class="d-flex">
                    <select
                      v-model.number="selectedGalpon"
                      class="custom-select custom-select-lg mr-2"
                      style="width: 130px"
                      @change="handleGalponChange"
                    >
                      <option value="-1">General</option>
                      <option
                        v-for="galpon in galpones"
                        :key="galpon.id"
                        :value="galpon.id"
                      >
                        Galpon {{ galpon.numero }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-if="datosTabla.datosSemana && datosTabla.datosSemana.length > 0"
              class="table-hover table-striped table-bordered"
              style="overflow-x: auto"
            >
              <div class="table-container">
                <table class="table table-sm">
                  <thead>
                    <th class="text-center" style="width: 84px">Sem</th>
                    <th class="text-center">Saldo Aves</th>
                    <th class="text-center">% Mort. Sem</th>
                    <th class="text-center">% Mort. Perc Tab</th>
                    <th class="text-center">% Mort. Acum</th>
                    <th class="text-center">% Mort. Perc Acum Tab</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(semana, index) in datosTabla.datosSemana"
                      :key="index"
                    >
                      <td align="center">
                        <span class="h2">{{ semana.numero }}</span>
                      </td>
                      <td align="center">{{ semana.saldo_aves }}</td>
                      <td align="center">
                        {{ semana.mortalidad_perc | formatPercentage }}
                      </td>
                      <td
                        align="center"
                        :style="{ backgroundColor: '#efb810' }"
                      >
                        {{ semana.mort_sel_perc_tabla | formatPercentage }}
                      </td>
                      <td align="center">
                        {{ semana.mortalidad_perc_acum | formatPercentage }}
                      </td>
                      <td
                        align="center"
                        :style="{ backgroundColor: '#efb810' }"
                      >
                        {{ semana.mort_sel_perc_acum_tabla | formatPercentage }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="datosTabla.cargando" class="text-center my-4">
                <p class="text-muted">Cargando tabla...</p>
              </div>
            </div>
            <div v-else class="text-center">
              <p>No hay datos disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  filters: {
    formatPercentage(value) {
      const formatted = (value || 0).toFixed(2);
      return `${formatted} %`;
    },
  },
  data() {
    return {
      datosTabla: {
        datosSemana: [],
        cargando: false,
      },
      galpones: [],
      selectedGalpon: -1,
    };
  },
  mounted() {
    this.cargarDatos();
    this.cargarGalpones();
  },
  methods: {
    async cargarDatos() {
      let galponToRequest = "";
      if (this.selectedGalpon !== -1) {
        const galpon = this.galpones.find(
          (galpon) => galpon.id === this.selectedGalpon,
        );
        if (galpon && galpon.lotes?.length > 0) {
          galponToRequest = galpon.lotes.at(0).pivot.id;
        }
      }

      this.datosTabla.cargando = true;

      const { data } = await axios.get(
        "/api/levante/inventario-aves/" + galponToRequest,
        {
          params: {
            tipo: this.selectedOption,
          },
        },
      );
      this.datosTabla = data;
      this.datosTabla.cargando = false;
    },

    async cargarGalpones() {
      const { data } = await axios.get("/api/galpon");
      this.galpones = data;
    },
    handleGalponChange() {
      this.cargarDatos();
    },
  },
};
</script>

<style scoped>
.card .table tbody td:last-child {
  display: table-cell;
  width: auto;
  padding-right: 6px;
}

.card .table thead th:last-child {
  display: table-cell;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}
</style>
