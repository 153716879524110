<template>
  <div v-if="cliente" class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Detalles del Cliente</h4>
            </template>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Identificacion</th>
                    <td>{{ cliente.identificacion }}</td>
                  </tr>
                  <tr>
                    <th>Correo</th>
                    <td>{{ cliente.correo }}</td>
                  </tr>
                  <tr>
                    <th>Telefono</th>
                    <td>{{ cliente.telefono }}</td>
                  </tr>
                  <tr>
                    <th>Direccion</th>
                    <td>{{ cliente.direccion }}</td>
                  </tr>
                  <tr>
                    <th>Razon Social</th>
                    <td>{{ cliente.razon_social }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <p>Cargando detalles del cliente..</p>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      cliente: null,
    };
  },
  mounted() {
    this.verificarRuta();
  },
  methods: {
    async cargarCliente() {
      try {
        const { data } = await axios.get(
          `/api/cliente/${this.$route.params.id}`,
        );
        this.cliente = data;
      } catch (error) {
        console.error("Hubo un error al obtener el cliente:", error);
        this.$route.push({ name: "Cliente" });
      }
    },
    async verificarRuta() {
      try {
        const id = parseInt(this.$route.params.id, 10);
        if (isNaN(id)) throw new Error("Invalid ID");
        await this.cargarCliente();
      } catch (error) {
        this.$router.push({ name: "Cliente" });
      }
    },
  },
};
</script>
